<template>
  <div>
    <router-view />
  </div>
</template>

<script>
import { onUnmounted } from '@vue/composition-api'
import packStore from '@/store/main/warehouse/pack'
import servicePickStore from '@/store/main/warehouse/pick/service-order'
import transferPickStore from '@/store/main/warehouse/pick/transfer'
import subRentalStore from '@/store/main/warehouse/pick/sub-rental'
import config from './config'

export default {
  name: 'Warehouse',
  components: { },
  setup() {
    const { MODULE_NAME, store, model } = config()

    // Register on enter section
    if (!store.hasModule(MODULE_NAME)) store.registerModule(MODULE_NAME, model)

    // UnRegister on leave
    onUnmounted(() => {
      if (store.hasModule(MODULE_NAME)) store.unregisterModule(MODULE_NAME)
      if (transferPickStore.hasModule('pick-transfer-order')) transferPickStore.unregisterModule('pick-transfer-order')
      if (servicePickStore.hasModule('pick-service-order')) servicePickStore.unregisterModule('pick-service-order')
      if (subRentalStore.hasModule('sub-rental-pick')) subRentalStore.unregisterModule('sub-rental-pick')
      if (packStore.hasModule('pack-orders')) packStore.unregisterModule('pack-orders')
    })
  },
  methods: {
  },
}
</script>

<style scoped>

</style>
