import axios from '@/libs/axios'
import { customActionGet } from '@core/utils/utils'

const endpointService = 'service-order'
const getServiceOrder = (_, { id, queryParams = {} }) => customActionGet(`/${endpointService}/${id}/fulfillment`, queryParams)
const pickUpServiceOrder = (_, payload) => axios.post(`${endpointService}/pick`, payload)
export default {
  getServiceOrder,
  pickUpServiceOrder,
}
