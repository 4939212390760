import { customActionGet } from '@core/utils/utils'
import axios from '@/libs/axios'

const dispatchCheckout = (callBack, { body, orderEndpoint }) => axios.post(`${orderEndpoint}/dispatch-checkout`, body)

const getOrderItem = (callBack, { id, orderEndpoint }) => customActionGet(`${orderEndpoint}/${id}/fulfillment`, {}, callBack.commit, 'SET_ORDER_ITEM').catch(err => {
  this.errorNotification(this, err)
})

const packOrderItems = (_, { body, orderEndpoint }) => axios.post(`${orderEndpoint}/pack`, body).then(() => getOrderItem(_, { id: body.order_id, orderEndpoint }))

const dispatchOrder = (_, { body, orderEndpoint }) => axios.post(`${orderEndpoint}/dispatch`, body).then(() => {
  if (orderEndpoint.includes('transfer') || orderEndpoint.includes('service-order')) {
    return dispatchCheckout(_, { body, orderEndpoint })
  }
  return Promise.resolve()
})
const removeContainer = (_, { body, orderEndpoint }) => axios.post(`${orderEndpoint}/remove-container`, body)

const removeAsset = (_, { body, orderEndpoint }) => axios.post(`${orderEndpoint}/remove-from-container`, body)

const searchContainerBySku = (_, params) => axios.get('catalog/stocks', { params })

export default {
  getOrderItem,
  removeContainer,
  removeAsset,
  searchContainerBySku,
  packOrderItems,
  dispatchOrder,
}
