import axios from '@/libs/axios'

const endpoint = 'orders/sub-rental'

const getOrders = (ctx, queryParams) => axios.get(`${endpoint}/${queryParams.order_id}/fulfillment`)

const pickUpOrder = (_, payload) => axios.post(`${endpoint}/pick`, payload)

const splitOrders = (_, { id, payload }) => axios.post(`${endpoint}/${id}/split`, payload)

export default {
  getOrders,
  pickUpOrder,
  splitOrders,
}
