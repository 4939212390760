import * as _ from 'lodash'

export default {
  SET_ORDER_ITEM(state, orderItem) {
    state.orderItem = { ...orderItem }
    state.orderItems.assets = _.filter(orderItem.order_items, { pack_at: null })
    state.orderItems.containers = _.reduce(orderItem.order_items, (acc, item) => {
      if (item.pack_at && !item.parent_id) {
        acc.push({
          ...item,
          children: _.filter(orderItem.order_items, { parent_id: item.id }),
        })
      }
      return acc
    }, [])
  },
  SET_ORDER_ITEMS(state, orderItems) {
    state.orderItems = { ...orderItems }
  },
  SET_ORDER_ITEM_ASSETS(state, assets) {
    state.orderItems.assets = assets
  },
  SET_ORDER_ITEM_CONTAINERS(state, containers) {
    state.orderItems.containers = containers
  },
}
