import actions from './action'
import mutations from './mutation'
import state from './state'
import getters from './getters'

export default {
  namespaced: true,
  actions,
  mutations,
  getters,
  state,
}
