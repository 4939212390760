export default {
  SET(state, data) {
    state.serviceOrder = { ...data }
  },
  SET_SERVICE_INFORMATION(state, data) {
    state.getServiceInformation = data
    state.assetsItem = data?.order_items
  },
  SET_ASSETS_ITEM(state, data) {
    state.assetsItem = data
  },
  SET_RE_RENDER_COMPONENT_KEY(state) {
    state.reRenderComponentKey += 1
  },
}
